import * as React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CalloutBanner from "../components/calloutBanner"
import Modal from "../components/modal"

import "../scss/pages/news.scss"


class NewsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showModal: false, modalContent:{youtubeId: '', title: ''}};
    this.handleModalClick = this.handleModalClick.bind(this);
    this.handleNewsBtnClick = this.handleNewsBtnClick.bind(this);
  }

  handleModalClick(e, videoId, title) {
    e.preventDefault();
    if(videoId || title) {
      this.setState(state => ({
        showModal: !state.showModal,
        modalContent: {youtubeId: videoId, title: title}
      }));
    } else {
      this.setState(state => ({
        showModal: !state.showModal,
        modalContent: {youtubeId: '', title: ''}
      }));
    }
  }

  handleNewsBtnClick(e) {
    const newsWrapper = document.querySelector(".news__wrapper");
    newsWrapper.classList.add("show-all");
  }

  componentDidMount() {
    // Replacing &reg; elements
    let regElements = document.querySelectorAll("main p, main .btn, .news__title, main dt, main dd, .callout--banner__desc"); 
    regElements.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace(/®/g, "<sup>&reg;</sup>");
    })

    // Adding &nbsp;
    let elText = document.querySelectorAll(".featured p, .news__title"); 
      elText.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace('way we plant', "way we&nbsp;plant");
      el.innerHTML = el.innerHTML.replace('Ag Movement', "Ag&nbsp;Movement");
    })

    // load more button
    

  }
  
  render() {
    // Component Content
    const calloutBanner = get(this, 'props.data.contentfulCalloutBanner');
    const newsArticles = get(this, 'props.data.allContentfulNews.edges');
    
    // Images
    const playBtnBlue = get(this, 'props.data.playBtnBlue');
    const featuredVideoImg = get(this, 'props.data.featuredVideoImg');
    const externalIcon = get(this, 'props.data.externalIcon');
    

    return (
      <Layout bodyClass="news">
        <Seo title="News" description="The latest news and updates on SIMPAS and SIMPAS-Applied Solutions (SaS)." />

        <section className="bg--black">
          <div className="container">
            <div className="featured text-center flow">
              <h1>News</h1>
              <a 
                href="#!" 
                onClick={(e) => this.handleModalClick(e, 'U5_mtfumOA8', 'Featured: Prescription Application')}
                className="featured__img-wrapper"
              >
                <Img 
                  fluid={featuredVideoImg.fluid}
                  alt={featuredVideoImg.description}
                  className="featured__img"
                />
              </a>
              <a 
                href="#!" 
                onClick={(e) => this.handleModalClick(e, 'U5_mtfumOA8', 'Featured: Prescription Application')}>
                Featured: Prescription Application<img src={playBtnBlue.fluid.src} className="video__icon" alt="Play Video Icon" />
              </a>
              <p className="featured__desc">See why farmers like Jason Orr of Rowley, Iowa, think SIMPAS-applied Solutions™ are about to change the way the industry approaches in-furrow&nbsp;application.</p>
            </div>
          </div>
        </section>

        <section>
          <div className="container text-center">
            <h2>Media Contact</h2>
            <p>Christin Yates</p>
            <a href="mailto:cyates@archermalmo.com" target="_blank" rel="noopener noreferrer">CYates@archermalmo.com</a>
          </div>
        </section>

        <section className="bg--gray">
          <div className="container flow--thick">
            <div className="row news__wrapper">
              {newsArticles.map(({node}, index) => {
                return (
                  <div 
                    className="col-12 col-sm-6 col-md-4 news" 
                    key={node.id}
                  >
                    <div className="news__item flow--thin">
                      <div className="news__content flow--thin">
                        {/* Featured Image */}
                        <Img 
                          fluid={node.featuredImage.fluid} 
                          className="news__img"
                          alt={node.featuredImage.description}
                        />

                        {/* Publication Date */}
                        <p className="news__date">{node.publicationDate}</p>

                        {/* Headline */}
                        <a href={node.link} className="news__title" target="_blank" rel="noopener noreferrer">
                          {node.headline}<img src={externalIcon.fluid.src} className="external-icon" alt="" /></a>

                        {/* Publication Logo */}
                        {node.publicationLogo &&
                          <Img 
                          fluid={node.publicationLogo.fluid} 
                          className="news__publisher"
                          alt={node.publicationLogo.description}
                          />
                        }
                      </div>
                      {/* Button */}
                      <a href={node.link} className="btn news__btn" target="_blank" rel="noopener noreferrer">{node.linkText}</a>
                    </div>
                  </div>
                )
              })}
            </div> 

            <div className="text-center load-news__wrapper">
              <button 
                className="btn" 
                id="load-news__btn"
                onClick={(e) => this.handleNewsBtnClick(e)}
              >Load More News</button>
            </div>
          </div>
        </section>


        <CalloutBanner data={calloutBanner}/>

        {/* Modal */}
        <Modal 
          onClose={(e) => this.handleModalClick(e)}
          show={this.state.showModal}
          title={this.state.modalContent.title}
          >
            {this.state.youtubeId !== '' &&
              <div className="iframe__wrapper">
                <iframe width="100%" height="100%" src={'https://www.youtube.com/embed/' + this.state.modalContent.youtubeId + '?autoplay=1'} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            }
        </Modal>
        
      </Layout>
    )
  }
}
export default NewsPage


export const NewsPageQuery = graphql`
  query NewsPageQuery {
    allContentfulNews(sort: {fields: publicationDate, order: DESC}) {
      edges {
        node {
          id
          headline
          publicationDate(formatString: "LL")
          featuredImage {
            fluid (maxWidth: 800, quality: 100)  {
              ...GatsbyContentfulFluid_tracedSVG
            }
            description
          }
          publicationLogo {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyContentfulFluid
            }
            description
          }
          link
          linkText
        }
      }
    }
    videoList: contentfulList(listName: {eq: "Video List"}) {
      id
      listItems {
        ... on ContentfulVideo {
          id
          title
          youtubeId
          description {
            description
          }
          thumbnail {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_tracedSVG
            }
            description
          }
        }
      }
    }
    contentfulCalloutBanner(calloutTitle: {eq: "Learn More"}) {
      id
      backgroundColor
      calloutTitle
      calloutSubtitle
      linkText
      calloutLink {
        linkUrl
      }
    }
    featuredVideoImg: contentfulAsset(title: {eq: "news-application-thumbnail"}) {
      id
      description
      fluid (maxWidth: 960, quality: 100) 
      {
        ...GatsbyContentfulFluid
      }
    }
    playBtnBlue: contentfulAsset(title: {eq: "play-icon-blue"}) {
      id
      description
      fluid (maxWidth: 100) 
      {
        src
      }
    }
    externalIcon: contentfulAsset(title: {eq: "open-icon"}) {
      id
      description
      title
      fluid
      {
        ...GatsbyContentfulFluid
      }
    }
  }
`